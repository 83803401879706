













































import { Component, Ref, Vue } from "vue-property-decorator";
import { NotificationState } from "@/store/modules/notification";
import itemPriceRangeService from "@/service/itemPriceRangeService";
import ItemPriceRangeEdit from "@/components/organisms/item_price_range/ItemPriceRangeEditDialog.vue";
import ItemPriceRangeDelete from "@/components/organisms/item_price_range/ItemPriceRangeDeleteDialog.vue";
import { ItemPriceRange } from "@/graphql/client";

@Component({ components: { ItemPriceRangeEdit, ItemPriceRangeDelete } })
export default class ItemPriceRangeList extends Vue {
  @Ref() readonly editDialog!: ItemPriceRangeEdit;
  @Ref() readonly deleteDialog!: ItemPriceRangeDelete;
  //---------------------------
  // data
  //---------------------------
  items: ItemPriceRange[] = [];
  headers = [
    {
      text: "名称",
      value: "name",
    },
    {
      text: "金額（下限）",
      value: "lowerLimit",
      width: "15%",
    },
    {
      text: "金額（上限）",
      value: "upperLimit",
      width: "15%",
    },
    {
      text: "表示順",
      value: "displayOrder",
      width: "10%",
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  loadingDataGrid = false;

  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    itemPriceRangeService.allItemPriceRanges().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }

  /**
   * 商品価格帯編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(itemPriceRangeService.defaultItemPriceRange);
  }

  /**
   * 商品価格帯編集ダイアログを表示します.
   */
  public openEditDialog(item: ItemPriceRange): void {
    this.editDialog.open(item);
  }

  /**
   * 商品価格帯編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: ItemPriceRange): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("商品価格帯を更新しました！");
  }

  /**
   * 商品価格帯編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: ItemPriceRange): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("商品価格帯を登録しました！");
  }

  /**
   * 商品価格帯削除ダイアログを表示します.
   */
  public openDeleteDialog(item: ItemPriceRange): void {
    this.deleteDialog.open(Object.assign({}, item));
  }

  /**
   * 商品価格帯削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: ItemPriceRange): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("商品価格帯を削除しました！");
  }
}

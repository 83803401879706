










































































































import { ItemPriceRange } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import itemPriceRangeService from "@/service/itemPriceRangeService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import MixinValidator from "@/components/mixins/item_price_range/MixinItemPriceRangeEditDialogValidator.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import moment from "moment";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class ItemPriceRangeEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly itemPriceRangeForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: ItemPriceRange = itemPriceRangeService.defaultItemPriceRange;
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の登録日時を取得
   */
  get formattedCreatedAt(): string {
    return this.item.createdAt
      ? moment(this.item.createdAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の更新日時を取得
   */
  get formattedUpdatedAt(): string {
    return this.item.updatedAt
      ? moment(this.item.updatedAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }

  //---------------------------
  // methods
  //---------------------------
  async fetchData(item: ItemPriceRange): Promise<void> {
    this.item = itemPriceRangeService.defaultItemPriceRange;
    if (item.guid) {
      const result = await itemPriceRangeService.getItemPriceRange(item.guid);
      if (result) {
        this.item = result;
      }
    }
  }
  /**
   * ダイアログを表示します.
   */
  public async open(item: ItemPriceRange): Promise<void> {
    await this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      console.log("save");
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        itemPriceRangeService
          .updateItemPriceRange(this.item)
          .then((result) => {
            this.isProgressing = true;
            this.notifyUpdateSuccess(result as ItemPriceRange);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("商品価格帯の更新ができませんでした.");
          });
      } else {
        //create
        itemPriceRangeService
          .createItemPriceRange(this.item)
          .then((result) => {
            this.isProgressing = true;
            this.notifyCreateSuccess(result as ItemPriceRange);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("商品価格帯の登録ができませんでした.");
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: ItemPriceRange): ItemPriceRange {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: ItemPriceRange): ItemPriceRange {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}

import { ItemPriceRange } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * 商品価格帯に関する機能を提供します.
 */
class ItemPriceRangeService {
  /**
   * 初期状態の ItemPriceRange
   */
  public get defaultItemPriceRange(): ItemPriceRange {
    return {
      guid: "",
      name: "",
      displayOrder: 0,
      lowerLimit: 0,
      upperLimit: 0,
      createdAt: "",
      updatedAt: "",
    } as ItemPriceRange;
  }

  /**
   * すべての商品価格帯を取得して返します.
   * @returns すべての商品価格帯
   */
  public async allItemPriceRanges() {
    const response = await sdk.itemPriceRanges();
    if (response?.itemPriceRanges) {
      return response.itemPriceRanges as ItemPriceRange[];
    }
    return [] as ItemPriceRange[];
  }
  /**
   * 指定したGUIDに一致するd商品価格帯を取得して返します.
   * @param guid d商品価格帯のGUID
   * @returns d商品価格帯
   */
  public async getItemPriceRange(guid: string) {
    const response = await sdk.itemPriceRange({ itemPriceRangeGuid: guid });
    return response.itemPriceRange as ItemPriceRange;
  }

  /**
   * d商品価格帯を登録します.
   * @param input d商品価格帯の登録情報
   * @returns 登録完了後のd商品価格帯
   */
  public async createItemPriceRange(input: ItemPriceRange) {
    try {
      const response = await sdk.createItemPriceRange({
        createItemPriceRangeInput: {
          name: input.name,
          lowerLimit: input.lowerLimit,
          upperLimit: input.upperLimit,
          displayOrder: input.displayOrder,
        },
      });

      if (response?.createItemPriceRange) {
        return response.createItemPriceRange;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * d商品価格帯を更新します.
   * @param input d商品価格帯の更新情報
   * @returns 更新完了後のd商品価格帯
   */
  public async updateItemPriceRange(input: ItemPriceRange) {
    try {
      const response = await sdk.updateItemPriceRange({
        updateItemPriceRangeInput: {
          guid: input.guid,
          name: input.name,
          lowerLimit: input.lowerLimit,
          upperLimit: input.upperLimit,
          displayOrder: input.displayOrder,
        },
      });

      if (response?.updateItemPriceRange) {
        return response.updateItemPriceRange;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * d商品価格帯を削除します.
   * @param input d商品価格帯の削除情報
   * @returns 削除したd商品価格帯
   */
  public async deleteItemPriceRange(input: ItemPriceRange) {
    try {
      const response = await sdk.deleteItemPriceRange({
        deleteItemPriceRangeInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteItemPriceRange) {
        return response.deleteItemPriceRange;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ItemPriceRangeService();

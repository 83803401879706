










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import ItemPriceRangeList from "@/components/organisms/item_price_range/ItemPriceRangeList.vue";

export default {
  components: {
    SubPageTemplate,
    ItemPriceRangeList,
  },
};
